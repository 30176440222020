import React from "react";
import { Link } from "gatsby";
import Fade from "react-reveal/Fade";

import { Container } from "components/container/Container";
import { Row } from "components/row/Row";

import s from "./Menu.scss";

interface IMenuProps {
  close: React.MouseEventHandler<JSX.Element | HTMLElement>;
}

export const Menu = ({ close }: IMenuProps) => (
  <div className="menu">
    <div className={s.menu}>
      <Container>
        <div className={s.menu__block}>
          <Row>
            <div className={s.menu__col}>
              <Fade cascade>
                <ul>
                  <li>
                    <Link to="/#about-heritage" onClick={close}>
                      About Heritage
                    </Link>
                  </li>
                  <li>
                    <Link to="/#natural-materials" onClick={close}>
                      Natural Materials
                    </Link>
                  </li>
                  <li>
                    <Link to="/#signature-collection" onClick={close}>
                      Signature Collection
                    </Link>
                  </li>
                  <li>
                    <Link to="/#natural-collection" onClick={close}>
                      Natural Collection
                    </Link>
                  </li>
                </ul>
              </Fade>
            </div>
          </Row>
        </div>
      </Container>
    </div>
  </div>
);
