import * as React from "react";
import Helmet from "react-helmet";

import Logo from "assets/svg/heritage-beds-logo.svg";

import { helmet } from "utils/helmet";
import { Header } from "components/header/Header";
import { Link as HeaderLink } from "components/header/Link";
import { Footer } from "components/footer/Footer";
import { Devtools } from "components/devtools/Devtools";

import s from "./AppLayout.scss";

if (typeof window !== "undefined") {
  // eslint-disable-next-line global-require
  require("smooth-scroll")('a[href*="#"]', {
    speed: 1600,
    speedAsDuration: true,
    easing: "easeInOutCubic",
    offset: 95
  });
}

interface IAppLayoutProps {
  children: React.ReactNode;
}

const isDev = process.env.NODE_ENV === "development";

export default ({ children }: IAppLayoutProps) => (
  <div className={s.layout}>
    <Helmet {...helmet} />

    <Header>
      <HeaderLink name="About Heritage" to="/#about-heritage" />

      <HeaderLink name="Natural Materials" to="/#natural-materials" />

      <HeaderLink name="Signature Collection" to="/#signature-collection" />

      <HeaderLink name="Natural Collection" to="/#natural-collection" />
    </Header>

    {children}

    <Footer logo={<Logo />} />

    {isDev && <Devtools />}
  </div>
);
