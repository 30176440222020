import * as React from "react";
import { Link } from "gatsby";

import { Container } from "components/container/Container";

import s from "./Footer.scss";

interface IFooterProps {
  logo: React.ReactNode;
}

export const Footer = ({ logo }: IFooterProps) => (
  <div className={s.footer}>
    <Container>
      <div className={s.footer__content}>
        <Link to="/">{logo}</Link>

        <span>Premium handmade beds &amp; mattresses</span>
      </div>
    </Container>
  </div>
);
