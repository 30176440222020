const title = "Heritage Bed Co";
const description =
  "The Heritage Bed Company is an exclusive maker of exquisitely handcrafted mattresses. We use only the finest natural fillings and traditional manufacturing techniques, handed down by generations of bed makers to produce a range of products that are amongst the best in the world.";

const favicon = require("assets/images/favicon.png");

export const helmet = {
  title,
  titleTemplate: "Heritage Bed Co - %s",
  htmlAttributes: { lang: "en" },
  meta: [
    { name: "description", content: description },
    {
      name: "viewport",
      content: "width=device-width, initial-scale=1, user-scalable=no"
    },
    { name: "apple-mobile-web-app-status-bar-style", content: "black" },
    { name: "msapplication-navbutton-color", content: "#353a3c" },
    { name: "msapplication-TileColor", content: "#353a3c" },
    { name: "theme-color", content: "#353a3c" },

    { property: "og:title", content: title },
    { property: "og:image:width", content: "880px" },
    { property: "og:image:height", content: "440px" },
    { property: "og:image:alt", content: description }
  ],
  link: [{ rel: "icon", type: "image/x-icon", href: favicon }]
};
